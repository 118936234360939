import React, { Dispatch, SetStateAction, useState } from "react"
import { Select, MenuItem, SelectChangeEvent, Divider } from "@mui/material"
import { Title, Button, Row, Column, Text } from "styled_components"
import { TicketOption, TicketOptionsContainer } from "./style"
import { Activity } from "backend/api/activities"
import { BookingItem } from "backend/api/bookings"
import { BookingDate } from "widget/DateTicketPicker"
import { ActivityPricesStatusEnum, BookingSourceEnum, BookingStatusEnum } from "helpers/constants"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

interface PickingTicket {
  name: string
  quantity: number
}

interface Props {
  activity: Activity
  newBookingInformations: BookingItem[]
  setNewBookingInformations: Dispatch<SetStateAction<BookingItem[]>>
  newBookingDate: BookingDate
  handleNext: (newBookingInformations: BookingItem[]) => void
}

const TicketPicker = ({
  activity,
  newBookingInformations,
  setNewBookingInformations,
  newBookingDate,
  handleNext,
}: Props) => {
  const { t } = useTranslation("dateTicketPicker")

  const [availableQuantity, setAvailableQuantity] = useState(newBookingDate.maxCapacity)
  const [quantities, setQuantities] = useState<PickingTicket[]>([])

  const filteredPrices = activity.prices.filter(
    (price) => price.status === ActivityPricesStatusEnum.ENABLED,
  )

  const handleChange = (name: string, event: SelectChangeEvent<any>) => {
    const newQuantity = parseInt(event.target.value, 10) // Parse the new quantity value from the event
    const existingTicket = quantities.find((q) => q.name === name) // Find if an entry already exists
    const previousQuantity = existingTicket ? existingTicket.quantity : 0 // Get previous quantity, defaulting to 0 if not found

    // Update the quantities array:
    // - If the ticket already exists, update its quantity
    // - If it does not exist and the new quantity is not zero, add a new entry
    if (existingTicket) {
      setQuantities(quantities.map((q) => (q.name === name ? { ...q, quantity: newQuantity } : q)))
    } else if (newQuantity !== 0) {
      setQuantities([...quantities, { name, quantity: newQuantity }])
    } else {
      // If new quantity is zero and the ticket does not exist, no need to add it
      setQuantities(quantities.filter((q) => q.name !== name))
    }

    // Update available quantity:
    // Increase or decrease based on the new quantity compared to the previous quantity
    setAvailableQuantity(availableQuantity - newQuantity + previousQuantity)
  }

  const handleValid = () => {
    const newTickets: BookingItem[] = quantities
      .map((quantity) => {
        const ticket = activity.prices.find((price) => price.name === quantity.name)
        return {
          from: newBookingDate.from,
          to: newBookingDate.to,
          activity_id: activity.id,
          numberOfUnit: quantity.quantity,
          priceName: ticket?.name || "",
          price: Number(ticket?.price) || 0,
          status: BookingStatusEnum.BOOKED,
          source: BookingSourceEnum.WIDGET,
        }
      })
      .filter((ticket: BookingItem | undefined) => ticket !== undefined)

    if (newTickets.length) {
      setNewBookingInformations([...newBookingInformations, ...newTickets])
    }
    handleNext([...newBookingInformations, ...newTickets])
  }

  return (
    <>
      <Row space margin="0 0 20px 0" width="100%" mobile="flex-direction: column;">
        <Title style={{textWrap: "nowrap"}} margin="0 0 5px 0" bold>
          Date :<Title margin="0 0 0 5px">{dayjs(newBookingDate.from).format("DD/MM/YYYY")}</Title>
          <Title margin="0 0 0 20px" bold>
            {t("hour")} :{" "}
            <Title margin="0 0 0 5px">{dayjs(newBookingDate.from).format("HH[h]mm")}</Title>
          </Title>
        </Title>
        <Title style={{textWrap: "nowrap"}} margin="0 0 5px 0" bold>
          {t("duration")} :{" "}
          <Title margin="0 0 0 5px">
            {`${dayjs(newBookingDate.to).diff(dayjs(newBookingDate.from), "hour").toString().padStart(2, "0")}h` +
              `${(dayjs(newBookingDate.to).diff(dayjs(newBookingDate.from), "minute") % 60).toString().padStart(2, "0")}`}
          </Title>
        </Title>
      </Row>
      <TicketOptionsContainer>
        {filteredPrices.map((option, index) => {
          const selectedQuantity = quantities.find(
            (quantity) => quantity.name === option.name,
          )?.quantity
          const currentAvailableQuantity =
            selectedQuantity && selectedQuantity >= availableQuantity
              ? selectedQuantity + availableQuantity
              : availableQuantity

          return (
            <>
              <TicketOption key={index + currentAvailableQuantity}>
                <Title bold>
                  {activity.informations.name} - {option.type ? t(option.type) : option.name}
                </Title>
                <Row margin="10px 0 0 0" space>
                  <Column style={{ maxWidth: "calc(100% - 90px)" }}>
                    <Title>{parseFloat(String(option.price)).toFixed(2)}€</Title>
                    <Text margin="10px 0 0 0">{option.description}</Text>
                  </Column>
                  <Select
                    value={selectedQuantity || 0}
                    onChange={(e) => handleChange(option.name, e)}
                    displayEmpty
                  >
                    <MenuItem key={0} value={0}>
                      0
                    </MenuItem>
                    {/* @ts-ignore */}
                    {[...Array(currentAvailableQuantity + 1 || 1).keys()].map(
                      (x) =>
                        x > 0 && (
                          <MenuItem key={x} value={x}>
                            {x}
                          </MenuItem>
                        ),
                    )}
                  </Select>
                </Row>
              </TicketOption>
              <Divider style={{ marginBottom: "30px" }} />
            </>
          )
        })}
        {quantities.length > 0 && (
          <Button width="100%" onClick={handleValid}>
            {t("validate")}
          </Button>
        )}
      </TicketOptionsContainer>
    </>
  )
}

export default TicketPicker
