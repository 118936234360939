import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import TextField from "@mui/material/TextField"
import { useTranslation } from "react-i18next"
import { Column, Title } from "styled_components"
import { FormContainer } from "./style"
import StripePayment from "./StripePayment"
import Discount from "./Discount"
import { Order } from "types/order.type"
import { Discount as DiscountType } from "backend/api/discounts"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import PaymentSuccessfull from "./PaymentSuccessfull"
import { getBookingsPrice } from "backend/api/bookings"

export interface PaymentForm {
  name: string
  email: string
  phoneNumber: string
  promotionCode: string
}

interface Props {
  order: Order
  stripeAccountId: string
  setDiscounts: Dispatch<SetStateAction<DiscountType[]>>
  discounts: DiscountType[]
  handleBack: () => void
}

const PaymentForm = ({ order, stripeAccountId, setDiscounts, discounts, handleBack }: Props) => {
  const { t } = useTranslation("bookingPayment")
  const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState<number | undefined>()
  const [discountCode, setDiscountCode] = useState<string>("")
  const [paymentStatus, setPaymentStatus] = useState<"error" | "success">()
  const [visitorInfos, setVisitorInfos] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
  })

  const totalPrice = order.bookings.reduce(
    (acc, booking) => acc + booking.price * booking.numberOfUnit,
    0,
  )

  const schema = z.object({
    name: z.string().nonempty({ message: t("form.name.error") }),
    email: z
      .string()
      .nonempty({ message: t("form.email.error") })
      .email({ message: t("form.email.error") }),
    phoneNumber: z.string().min(10, { message: t("form.phoneNumber.error") }),
    promotionCode: z.string().optional(),
  })

  const { control, handleSubmit, getValues, trigger, watch } = useForm({
    resolver: zodResolver(schema),
  })

  useEffect(() => {
    calculatePriceWithDiscount()
  }, [discounts])

  const calculatePriceWithDiscount = async () => {
    if (discounts && discounts.length > 0) {
      let priceWithDiscount = 0;
      for (const activity of order.selected_activities) {
        const discount = discounts.find((discount) => discount.activityId === activity.id)
        const activityBookings = order.bookings.filter(
          (booking) => booking.activity_id === activity.id,
        )
        const priceForOrder = await getBookingsPrice(activity.id, activityBookings, discount?.code)
        priceWithDiscount += priceForOrder.price
      }
      setTotalPriceWithDiscount(priceWithDiscount);
    }
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setVisitorInfos({
        fullName: getValues("name"),
        email: getValues("email"),
        phoneNumber: getValues("phoneNumber"),
      })
    })
    return () => subscription.unsubscribe()
  }, [watch])

  if (paymentStatus === "success") {
    return (
      <Column mobile="margin-top: 30px;" width="100%" center alignCenter>
        <PaymentSuccessfull visitorInfos={visitorInfos} />
      </Column>
    )
  }

  function StripeWrapper({ accountId, children }: { accountId: string, children: any}) {
    const [stripeObject, setStripeObject] = useState<any>(null);

    // This function will re-run if the accountId prop changes.
    useEffect(() => {
      const fetchStripeObject = async () => {
        // If there is no accountId, do not run the loadStripe function.
        if (accountId) {
          const res = await loadStripe(
            String(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY),
            {
              stripeAccount: accountId
            }
          );
          // When we have got the Stripe object, pass it into our useState.
          setStripeObject(res);
        }
      };
      fetchStripeObject();
    }, [accountId]);

    // If no Stripe object, do not render the Stripe Element.
    if (!stripeObject) {
      return <p>Loading...</p>;
    }

    // Once we have the Stripe object, load everything.
    return <Elements stripe={stripeObject}>{children}</Elements>;
  }

  return (
    <Column mobile="margin-top: 30px;" width="100%" center alignCenter>
      <FormContainer onSubmit={handleSubmit(() => {})}>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={t("form.name.label")}
              variant="outlined"
              error={!!error}
              helperText={error ? error.message : null}
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={t("form.email.label")}
              variant="outlined"
              error={!!error}
              helperText={error ? error.message : null}
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={t("form.phoneNumber.label")}
              variant="outlined"
              error={!!error}
              helperText={error ? error.message : null}
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />
        <Discount
          setDiscounts={setDiscounts}
          order={order}
          discountCode={discountCode}
          setDiscountCode={setDiscountCode}
        />
        <Title size="22px" margin="10px 0" width="100%">
          Total:{" "}
          {totalPriceWithDiscount ? (
            <>
              <span style={{ textDecorationLine: "line-through", margin: "0 10px" }}>
                {totalPrice.toFixed(2)}€
              </span>
              <span>{totalPriceWithDiscount.toFixed(2)}€</span>
            </>
          ) : (
            <span style={{ marginLeft: "10px" }}>{totalPrice.toFixed(2)}€</span>
          )}
        </Title>
        <StripeWrapper
          accountId={stripeAccountId}
        >
          <StripePayment
            t={t}
            bookings={order.bookings}
            visitorInfos={visitorInfos}
            triggerFormValidation={trigger}
            discountCode={discountCode}
            handleBack={handleBack}
            paymentStatus={paymentStatus}
            setPaymentStatus={setPaymentStatus}
          />
        </StripeWrapper>
      </FormContainer>
    </Column>
  )
}

export default PaymentForm
