const dateTicketPicker: any = {
    en: {
      previous: 'Previous',
      next: 'Next',
      bookingInProgress: 'Booking in progress',
      ticketSelection: 'Ticket selection',
      selectDate: 'Select a date',
      noAvailableSlot: "No available slot for it's date, please select another date.",
      selectSlot: 'Select a slot',
      noAvailableSlots: 'No available slots',
      availablePlaces: 'Available places',
      hour: 'Hour',
      duration: 'Duration',
      validate: 'Validate',
      DEFAULT: 'Standard price',
      SENIOR: 'Child price',
      CHILD: 'Senior price',
    },
    fr: {
      previous: 'Précédent',
      next: 'Suivant',
      bookingInProgress: 'Réservation en cours',
      ticketSelection: 'Sélection de votre billet',
      selectDate: 'Sélectionnez une date',
      noAvailableSlot: "Pas de créneaux disponibles pour cette date, veuillez sélectionner une autre date.",
      selectSlot: 'Sélectionnez un créneau',
      noAvailableSlots: 'Aucun créneau disponible pour cette date, veuillez sélectionner une autre date.',
      noPlace: 'Aucune place disponible',
      availablePlaces: 'Places disponibles',
      hour: 'Heure',
      duration: 'Durée',
      validate: 'Valider',
      DEFAULT: 'Tarif Plein',
      SENIOR: 'Tarif Senior',
      CHILD: 'Tarif Enfant',
      
    },
  };
  
  export default dateTicketPicker;
  