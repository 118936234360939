import React, { Dispatch, SetStateAction, useMemo } from "react"
import { Title } from "styled_components" // Assurez-vous que le chemin d'accès est correct
import { Activity } from "backend/api/activities" // Vérifiez également ce chemin d'accès
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import 'dayjs/locale/fr';
import { DatePickerContainer } from "./style"
import { BookingDate } from "widget/DateTicketPicker"
import { useTranslation } from "react-i18next"
import { ActivityAvailabilities } from "types/activity.type"
import { ActivitySpecialSlotTypesEnum } from "helpers/constants"

interface Props {
  activity: Activity
  availabilitiesOnMonth: ActivityAvailabilities[]
  newBookingDate: BookingDate
  setNewBookingDate: Dispatch<SetStateAction<BookingDate>>
  setIsTimeSelected: Dispatch<SetStateAction<boolean>>
  setSelectedDay: Dispatch<SetStateAction<Date>>
}

const BookingDatePicker = ({
  activity,
  availabilitiesOnMonth,
  newBookingDate,
  setNewBookingDate,
  setIsTimeSelected,
  setSelectedDay,
}: Props) => {
  const { t } = useTranslation("dateTicketPicker")
  const minDate = dayjs(activity.hours.dates?.from || new Date());

  const enabledDays = useMemo(() => {
    return availabilitiesOnMonth.filter((item) => {
      return item.avaibilities.filter((item) => {
        const isASpecialSlotWhichRenderSlotUnavailable = 
          item.specialSlotType === ActivitySpecialSlotTypesEnum.MARK_AS_FULL ||
          item.specialSlotType === ActivitySpecialSlotTypesEnum.UNAVAILABLE
        return !isASpecialSlotWhichRenderSlotUnavailable
      }).length > 0
    })
    .map((item) => item.date)
  }, [availabilitiesOnMonth])

  const shouldDisableDate = (date: dayjs.Dayjs) => {
    return !enabledDays.includes(date.format('YYYY-MM-DD')) || date.isBefore(minDate, 'day');
  };

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const selectedDate = new Date(Date.UTC(date.year(), date.month(), date.date()));
  
      setSelectedDay(selectedDate);
      setNewBookingDate({
        ...newBookingDate,
        from: selectedDate.toISOString(),
        to: selectedDate.toISOString(),
      });
      setIsTimeSelected(false);
    }
  }

  return (
    <DatePickerContainer>
      <Title>{t("selectDate")}</Title>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DateCalendar
          sx={{ margin: "0" }}
          shouldDisableDate={shouldDisableDate}
          onChange={handleDateChange} // Ajout de la fonction de changement de date
          onMonthChange={handleDateChange}
        />
      </LocalizationProvider>
    </DatePickerContainer>
  )
}

export default BookingDatePicker
